<template>
  <div class='pb-8 px-16'>
    <h1 class='text-3xl font-semibold tracking-wide mr-2'>간접투자원장조회</h1>
    <div class='flex flex-row my-6'>
      <el-date-picker
        v-model='createdOn'
        style='width: 20rem;'
        type='date'
        placeholder='기준일'
        value-format='yyyy-MM-dd'
        @change='getIndirectSummary'>
      </el-date-picker>
      <button class='px-4 font-bold border rounded-md border-transparent hover:border-gray-500' @click='getIndirectSummary'>Refresh</button>
    </div>

    <div class='py-4 overflow-x-scroll summary-container mt-5'>
      <h1 class='px-4 text-xl font-semibold'>투자펀드</h1>
      <table>
        <thead class='text-center border-b border-t bg-gray-200 font-semibold tracking-wide border-b'>
          <table-header-filter-select
            v-bind:filter-texts.sync='summaryFilterTexts.share_code'
            keyName='share_code'
            v-bind:sorting-dir.sync='summarySortDir'
            v-bind:sorting-key.sync='summarySortKey'
            defaultLabel='펀드코드'
            :optionObject='summaries'
            class='px-2 py-2 text-left border-r border-l' />
          <table-header-filter-select
            v-bind:filter-texts.sync='summaryFilterTexts.name'
            keyName='name'
            v-bind:sorting-dir.sync='summarySortDir'
            v-bind:sorting-key.sync='summarySortKey'
            defaultLabel='펀드명'
            :optionObject='summaries'
            class='px-2 py-2 text-left border-r' />
          <table-header-filter-select
            v-bind:filter-texts.sync='summaryFilterTexts.fund_type'
            keyName='fund_type'
            v-bind:sorting-dir.sync='summarySortDir'
            v-bind:sorting-key.sync='summarySortKey'
            defaultLabel='펀드구분'
            :optionObject='summaries'
            class='px-2 py-2 text-left border-r' />
          <table-header-filter-select
            v-bind:filter-texts.sync='summaryFilterTexts.fund_form'
            keyName='fund_form'
            v-bind:sorting-dir.sync='summarySortDir'
            v-bind:sorting-key.sync='summarySortKey'
            defaultLabel='펀드형태'
            :optionObject='summaries'
            class='px-2 py-2 text-left border-r' />
          <table-header-filter-select
            v-bind:filter-texts.sync='summaryFilterTexts.base_asset_name'
            keyName='base_asset_name'
            v-bind:sorting-dir.sync='summarySortDir'
            v-bind:sorting-key.sync='summarySortKey'
            defaultLabel='기초자산명'
            :optionObject='summaries'
            class='px-2 py-2 text-left border-r' />
          <table-header-filter-select
            v-bind:filter-texts.sync='summaryFilterTexts.old_or_new'
            keyName='old_or_new'
            v-bind:sorting-dir.sync='summarySortDir'
            v-bind:sorting-key.sync='summarySortKey'
            defaultLabel='구/신'
            :optionObject='summaries'
            class='px-2 py-2 text-left border-r' />
          <table-header-filter-select
            v-bind:filter-texts.sync='summaryFilterTexts.base_asset_class'
            keyName='base_asset_class'
            v-bind:sorting-dir.sync='summarySortDir'
            v-bind:sorting-key.sync='summarySortKey'
            defaultLabel='투자형태'
            :optionObject='summaries'
            class='px-2 py-2 text-left border-r' />
          <td class='px-2 py-2 border-r'>약정금액</td>
          <td class='px-2 py-2 border-r'>출자원금액</td>
          <td class='px-2 py-2 border-r'>현재원금액</td>
          <td class='px-2 py-2 border-r'>현재평가액</td>
          <td class='px-2 py-2 border-r'>평가수익률(%)</td>
          <td class='px-2 py-2 border-r'>회수수익률(%)</td>
          <td class='px-2 py-2 border-r'>투자단가</td>
          <td class='px-2 py-2 border-r'>현재주가</td>
          <td class='px-2 py-2 border-r'>괴리율(%)</td>
          <td class='px-2 py-2 border-r'>펀드결성일</td>
          <td class='px-2 py-2 border-r'>펀드만기일</td>
        </thead>
        <tbody>
          <tr v-for='(summary, index) in filteredSummaries'
            :key='`summary-${summary.share_code}`'
            @click='clickSummary(summary, index)'
            :class='highlightRow("summary", index)'>
            <td class='px-2 py-2 text-left border-l border-r'>{{ summary.share_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ summary.name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ summary.fund_type }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ summary.fund_form }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ summary.base_asset_name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ summary.old_or_new }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ summary.base_asset_class }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.contract_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.investment_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.acquired_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.close_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.unrealized_gain_rate) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.collection_gain_rate) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.investment_price) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.close_price) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(summary.gap_rate) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ summary.fund_start_date }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ summary.fund_end_date }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <el-radio-group v-model='selectedRadio' size='large'>
      <el-radio-button label='펀드정보'></el-radio-button>
      <el-radio-button label='보유/거래'></el-radio-button>
    </el-radio-group>

    <div v-if='showListing' class='py-4 overflow-x-scroll  mt-5'>
      <h1 class='px-4 text-xl font-semibold'>펀드정보</h1>
        <table>
          <tbody>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-l border-r w-64'>펀드결성일</td>
              <td class='px-2 py-2 text-left border-r w-64'>{{ listing.fund_start_date }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>펀드만기일</td>
              <td class='px-2 py-2 text-left border-r'>{{ listing.fund_end_date }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>펀드약정총액</td>
              <td class='px-2 py-2 text-right border-r'>{{ numberStyle(listing.fund_contract_value) }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>펀드약정총좌수</td>
              <td class='px-2 py-2 text-right border-r'>{{ numberStyle(shareNumber(listing.fund_contract_value)) }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>1좌금액</td>
              <td class='px-2 py-2 text-right border-r'>{{ numberStyle(listing.fund_share_value) }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>만기연장조건</td>
              <td class='px-2 py-2 text-left border-r'>{{ listing.fund_delay_condition }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>기준수익률(%)</td>
              <td class='px-2 py-2 text-right border-r'>{{ listing.base_earning_rate }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>성과보수율(%)</td>
              <td class='px-2 py-2 text-right border-r'>{{ listing.carried_interest_rate }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>관리보수율(%)</td>
              <td class='px-2 py-2 text-right border-r'>{{ listing.management_interest_rat }}</td>
            </tr>
            <tr class='tracking-wide border-t'>
              <td class='px-2 py-2 text-left border-r border-l'>관리보수기준</td>
              <td class='px-2 py-2 text-left border-r'>{{ listing.management_interest_standard }}</td>
            </tr>
          </tbody>
        </table>
    </div>

    <div v-if='showPosition' class='py-4 overflow-x-scroll position-container mt-5'>
      <h1 class='px-4 text-xl font-semibold'>보유내역</h1>
      <table>
        <tbody>
          <tr class='bg-gray-200 font-semibold tracking-wide border-b'>
            <table-header-filter-select
              v-bind:filter-texts.sync='positionFilterTexts.short_name'
              keyName='short_name'
              v-bind:sorting-dir.sync='positionSortDir'
              v-bind:sorting-key.sync='positionSortKey'
              colspanNum='1'
              rowspanNum='2'
              defaultLabel='펀드'
              :optionObject='positions'
              class='px-2 py-2 text-left border-r' />
            <td class='px-2 py-2 text-center border-l border-r border-b border-t' colspan='2'>약정금</td>
            <td class='px-2 py-2 text-center border-l border-r border-b border-t' colspan='2'>출자원금</td>
            <td class='px-2 py-2 text-center border-l border-r border-b border-t' colspan='2'>현재원금</td>
            <td class='px-2 py-2 text-center border-l border-r border-b border-t' colspan='2'>현재평가금</td>
            <td class='px-2 py-2 text-center border-l border-r border-b border-t' colspan='5'>회수금</td>
          </tr>
          <tr class='bg-gray-200 font-semibold tracking-wide border-b'>
            <td class='px-2 py-2 text-center border-l border-r border-b'>좌수</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>금액</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>좌수</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>금액</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>좌수</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>금액</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>현재가</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>금액</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>회수금</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>원금</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>이익</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>회수수익률(%)</td>
            <td class='px-2 py-2 text-center border-l border-r border-b'>회수율(%)</td>
          </tr>
          <tr v-for='(position, index) in filteredPositions'
            :key='`position-${index}-${position.id}-${JSON.stringify(position)}`'
            @click='clickPosition(position, index)'
            :class='highlightRow("position", index)'>
            <td class='px-2 py-2 text-left border-r border-l'>{{ position.short_name }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(shareNumber(position.contract_value)) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.contract_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(shareNumber(position.investment_value)) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.investment_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(shareNumber(position.acquired_value)) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.acquired_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.close_price) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.close_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.collection_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.original_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.realized_gain) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.collection_gain_rate) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(position.collection_rate) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan='100' class='text-right py-4'>
              <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
                @click='selectAll("position", positions.length)'>전체선택</button>
              <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
                @click='resetSelect("position")'>선택초기화</button>
              <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
                @click='copyToClipboard("position")'>Copy To Clipboard</button>
            </td>
          </tr>
        </tfoot>
      </table>

      <h1 class='px-4 py-4 text-xl font-semibold'>거래내역 ( {{ selectedPosition.short_name }} )</h1>
      <table>
        <thead class='bg-gray-200'>
          <table-header-filter-select
            v-bind:filter-texts.sync='orderFilterTexts.created_on'
            keyName='created_on'
            v-bind:sorting-dir.sync='orderSortDir'
            v-bind:sorting-key.sync='orderSortKey'
            defaultLabel='거래일자'
            :optionObject='orders'
            class='px-2 py-2 text-left border-r border-l' />
          <table-header-filter-select
            v-bind:filter-texts.sync='orderFilterTexts.settlement_date'
            keyName='settlement_date'
            v-bind:sorting-dir.sync='orderSortDir'
            v-bind:sorting-key.sync='orderSortKey'
            defaultLabel='결제일자'
            :optionObject='orders'
            class='px-2 py-2 text-left border-r border-b border-t' />
          <td class='px-2 py-2 text-center border-r border-b border-t'>종목코드</td>
          <td class='px-2 py-2 text-center border-r border-b border-t'>종목명</td>
            <table-header-filter-select
              v-bind:filter-texts.sync='orderFilterTexts.action_name'
              keyName='action_name'
              v-bind:sorting-dir.sync='orderSortDir'
              v-bind:sorting-key.sync='orderSortKey'
              defaultLabel='매수'
              :optionObject='orders'
              class='px-2 py-2 text-left border-r border-b border-t' />
          <td class='px-2 py-2 text-center border-r border-b border-t'>수량</td>
          <td class='px-2 py-2 text-center border-r border-b border-t'>단가</td>
          <td class='px-2 py-2 text-center border-r border-b border-t'>수수료</td>
          <td class='px-2 py-2 text-center border-r border-b border-t'>세금</td>
          <td class='px-2 py-2 text-center border-r border-b border-t'>배당</td>
          <table-header-filter-select
            v-bind:filter-texts.sync='orderFilterTexts.trade_type'
            keyName='trade_type'
            v-bind:sorting-dir.sync='orderSortDir'
            v-bind:sorting-key.sync='orderSortKey'
            defaultLabel='거래방법'
            :optionObject='orders'
            class='px-2 py-2 text-left border-r border-b border-t' />
          <table-header-filter-select
            v-bind:filter-texts.sync='orderFilterTexts.principal_type'
            keyName='principal_type'
            v-bind:sorting-dir.sync='orderSortDir'
            v-bind:sorting-key.sync='orderSortKey'
            defaultLabel='원금구분'
            :optionObject='orders'
            class='px-2 py-2 text-left border-r border-b border-t' />
          <td class='px-2 py-2 text-center border-l border-r border-b border-t'>원금</td>
        </thead>
        <tbody>
          <tr v-for='(order, index) in filteredOrders'
            :key='`order-${index}-${order.id}-${JSON.stringify(order)}`'
            @click='selectRow("order", index)'
            :class='highlightRow("order", index)'>
            <td class='px-2 py-2 text-left border-r border-l'>{{ order.created_on }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.settlement_date }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.share_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.action_name }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.quantity) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.price) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.fee) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.tax) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.dividend) }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.trade_type }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.principal_type }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.change_investment_value) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan='100' class='text-right py-4'>
              <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
                @click='selectAll("order", orders.length)'>전체선택</button>
              <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
                @click='resetSelect("order")'>선택초기화</button>
              <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
                @click='copyToClipboard("order")'>Copy To Clipboard</button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import PositionsApi                from '@/api/v1/positions'
import ListingsApi                 from '@/api/v1/listings'
import OrdersApi                   from '@/api/v1/orders'
import dayjs                       from 'dayjs'
import TableHeaderFilterSelect     from '@/components/TableHeaderFilterSelect.vue'

export default {
  name: 'IndirectPositions',
  components: {
    TableHeaderFilterSelect,
  },
  data () {
    return {
      createdOn: dayjs(new Date()).format('YYYY-MM-DD'),
      summaries: [],
      positions: [],
      orders: [],
      listing: {},
      selectedSummary: {},
      selectedPosition: {},
      selectedRadio: '',
      firstIndex: {summary: -1, order: -1, position: -1},
      secondIndex: {summary: -1, order: -1, position: -1},
      summarySortDir: '',
      summarySortKey: '',
      summaryFilterKeys: [
        'share_code',
        'name',
        'fund_type',
        'fund_form',
        'base_asset_name',
        'old_or_new',
        'base_asset_class',
      ],
      summaryFilterTexts: {
        share_code: [],
        name: [],
        fund_type: [],
        fund_form: [],
        base_asset_name: [],
        old_or_new: [],
        base_asset_class: [],
      },
      positionSortDir: '',
      positionSortKey: '',
      positionFilterKeys: [
        'short_name',
      ],
      positionFilterTexts: {
        short_name: [],
      },
      orderSortDir: '',
      orderSortKey: '',
      orderFilterKeys: [
        'created_on',
        'settlement_date',
        'action_name',
        'trade_type',
        'principal_type',
      ],
      orderFilterTexts: {
        created_on: [],
        settlement_date: [],
        action_name: [],
        trade_type: [],
        principal_type: [],
      },
    }
  },
  computed: {
    showListing () {
      return this.selectedRadio === '펀드정보'
    },
    showPosition () {
      return this.selectedRadio === '보유/거래'
    },
    sortedSummries () {
      if (this.summarySortDir === '') {
        return this.summaries
      } else {
        this.sortData (this.summaries, this.summarySortKey, this.summarySortDir)
        return this.summaries
      }
    },
    sortedPositions () {
      if (this.positionSortDir === '') {
        return this.positions
      } else {
        this.sortData (this.positions, this.positionSortKey, this.positionSortDir)
        return this.positions
      }
    },
    sortedOrders () {
      if (this.orderSortDir === '') {
        return this.orders
      } else {
        this.sortData (this.orders, this.orderSortKey, this.orderSortDir)
        return this.orders
      }
    },
    filteredSummaries () {
      return this.sortedSummries.filter ( data => this.inAnyData(data, this.summaryFilterKeys, this.summaryFilterTexts) )
    },
    filteredPositions () {
      return this.sortedPositions.filter ( data => this.inAnyData(data, this.positionFilterKeys, this.positionFilterTexts) )
    },
    filteredOrders () {
      return this.sortedOrders.filter ( data => this.inAnyData(data, this.orderFilterKeys, this.orderFilterTexts) )
    },
  },
  methods: {
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }

      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }

      return false
    },
    getIndirectSummary () {
      PositionsApi.getIndirectSummary({ created_on: this.createdOn }).then(resp => {
        this.summaries = resp
      })
    },
    highlightRow (indexKey, index) {
      return (index >= this.firstIndex[indexKey] && index <= this.secondIndex[indexKey]) ? 'tracking-wide border-b bg-blue-100' : 'tracking-wide border-b'
    },
    clickSummary (summary, index) {
      this.selectedSummary = summary
      this.getListing(summary)
      this.getPositionsByCode(summary)
      this.selectRow('summary', index)
    },
    clickPosition (position, index) {
      this.selectedPosition = position
      this.getOrdersByCode(position)
      this.selectRow('position', index)
    },
    getPositionsByCode (summary) {
      PositionsApi.getPositionsByDateCode({ created_on: this.createdOn, share_code: summary.share_code, is_direct: false }).then(resp => {
        this.positions = resp
      })
    },
    getListing (summary) {
      ListingsApi.getListing(summary.listing_id).then(resp => {
        this.listing = resp
      })
    },
    getOrdersByCode (position) {
      OrdersApi.getOrdersByCode({ created_on: this.createdOn, share_code: position.share_code, fund_id: position.fund_id }).then(resp => {
        this.orders = resp
      })
    },
    numberStyle (number) {
      return number ? Number(number).toLocaleString() : ''
    },
    shareNumber (value) {
      if (!this.listing.fund_share_value || parseFloat(this.listing.fund_share_value) === 0.0)
        return 0
      else
        return value / this.listing.fund_share_value
    },
    selectRow (indexKey, index) {
      if (event.shiftKey) {
        if (this.firstIndex[indexKey] === -1) {
          this.firstIndex[indexKey] = index
          this.secondIndex[indexKey] = index
        } else {
          if (index < this.firstIndex[indexKey]) {
            this.secondIndex[indexKey]= this.firstIndex[indexKey]
            this.firstIndex[indexKey]= index
          } else {
            this.secondIndex[indexKey] = index
          }
        }
      } else {
        this.firstIndex[indexKey] = index
        this.secondIndex[indexKey] = index
      }
    },
    selectAll (indexKey, objectLength) {
      this.firstIndex[indexKey] = 0
      this.secondIndex[indexKey] = objectLength - 1
    },
    resetSelect (indexKey) {
      this.firstIndex[indexKey] = -1
      this.secondIndex[indexKey] = -1
    },
    copyToClipboard (targetName) {
      let string = ''

      if (targetName === 'order') {
        string = this.orderHeaderString ()
        this.filteredOrders.forEach((order, index) => {
          if (index >= this.firstIndex[targetName] && index <= this.secondIndex[targetName])
            string += this.convertOrderToString(order)
        })
      } else {
        string = this.positionHeaderString ()
        this.filteredPositions.forEach((position, index) => {
          if (index >= this.firstIndex[targetName] && index <= this.secondIndex[targetName])
            string += this.convertPositionToString(position)
        })
      }

      this.$copyText(string)
    },
    positionHeaderString () {
      let string = ''

      string += '펀드' + '\t'
      string += '약정금 좌수' + '\t'
      string += '약정금 금액' + '\t'
      string += '출자원금 좌수' + '\t'
      string += '출자원금 금액' + '\t'
      string += '현재원금 좌수' + '\t'
      string += '현재원금 금액' + '\t'
      string += '현재평가금 현재가' + '\t'
      string += '현재평가금 금액' + '\t'
      string += '회수금' + '\t'
      string += '회수금 원금' + '\t'
      string += '회수금 이익' + '\t'
      string += '회수수익률' + '\t'
      string += '회수율'
      string += '\n'

      return string
    },
    convertPositionToString(position) {
      let string = ''

      string += position.short_name + '\t'
      string += this.shareNumber(position.contract_value) + '\t'
      string += position.contract_value + '\t'
      string += this.shareNumber(position.investment_value) + '\t'
      string += position.investment_value + '\t'
      string += this.shareNumber(position.acquired_value) + '\t'
      string += position.acquired_value + '\t'
      string += position.close_price + '\t'
      string += position.close_value + '\t'
      string += position.collection_value + '\t'
      string += position.original_value + '\t'
      string += position.realized_gain + '\t'
      string += position.collection_gain_rate + '\t'
      string += position.collection_rate + '\t'
      string += '\n'

      return string
    },
    orderHeaderString () {
      let string = ''

      string += '거래일자' + '\t'
      string += '결제일자' + '\t'
      string += '종목코드' + '\t'
      string += '종목명' + '\t'
      string += '매매구분' + '\t'
      string += '수량' + '\t'
      string += '단가' + '\t'
      string += '수수료' + '\t'
      string += '세금' + '\t'
      string += '배당' + '\t'
      string += '거래방법' + '\t'
      string += '원금구분' + '\t'
      string += '원금'
      string += '\n'

      return string
    },
    convertOrderToString(order) {
      let string = ''

      string += order.created_on + '\t'
      string += order.settlement_date + '\t'
      string += order.share_code + '\t'
      string += order.name + '\t'
      string += order.action_name + '\t'
      string += order.quantity + '\t'
      string += order.price + '\t'
      string += order.fee + '\t'
      string += order.tax + '\t'
      string += order.dividend + '\t'
      string += order.trade_type + '\t'
      string += order.principal_type + '\t'
      string += order.change_investment_value + '\t'
      string += '\n'

      return string
    },
    sortData (target, key, dir) {
      target.sort((aTarget, bTarget) => {
        let aValue, bValue
        if (typeof aTarget === 'string') {
          aValue = aTarget[key].toUpperCase()
          bValue = bTarget[key].toUpperCase()
        } else {
          aValue = aTarget[key]
          bValue = bTarget[key]
        }

        if (dir === 'asc') {
          if (aValue < bValue) {
            return -1
          } else if (aValue > bValue) {
            return 1
          } else {
            return 0
          }
        } else {
          if (aValue < bValue) {
            return 1
          } else if (aValue > bValue) {
            return -1
          } else {
            return 0
          }
        }
      })
    },
  },
  mounted () {
    this.getIndirectSummary()
  },
}
</script>
