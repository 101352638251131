import base from './base'

const getOrders = (filters) => base
  .get('/orders/', { params: filters }).then(resp => resp.data)

const getOrder = (orderId) => base
  .get(`/orders/${orderId}`).then(resp => resp.data)

const createOrder = (orderParams) => base
  .post('/orders/', { new_orders: orderParams}).then(resp => resp.data)

const cancelOrder = (orderId) => base
  .delete(`/orders/${orderId}`).then(resp => resp.data)

const patchOrders = (orderParams) => base
  .patch(`/orders/0`, {check_orders: orderParams}).then(resp => resp.data)

const getPeriodOrders = (filters) => base
  .get('orders/period_orders', { params: filters }).then(resp => resp.data)

const getSummaryOrders = (filters) => base
  .get('orders/summary_orders', { params: filters }).then(resp => resp.data)

const getOrdersByCode = (filters) => base
  .get('orders/orders_by_code', { params: filters }).then(resp => resp.data)

export default {
  getOrders: getOrders,
  getOrder: getOrder,
  createOrder: createOrder,
  cancelOrder: cancelOrder,
  patchOrders: patchOrders,
  getPeriodOrders: getPeriodOrders,
  getSummaryOrders: getSummaryOrders,
  getOrdersByCode: getOrdersByCode,
}
